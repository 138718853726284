import React, {} from 'react'
import {FooterWrapper, FooterLogoWrapper, FooterLinksWrapper, FooterLink} from "./style";
import {Icon} from "../../../../components/Icon";

const Footer = ({currentTheme}) => {
    return (
        <FooterWrapper>
            {
                !!(currentTheme.name === "marysia") ? <FooterLinksWrapper>
                        <FooterLink href="mailto:customerservice@marysia.com">Contact Us</FooterLink> | <FooterLink
                        target="_blank" href="https://marysia.com/pages/service">View full policy</FooterLink>
                    </FooterLinksWrapper>
                    :
                    <FooterLogoWrapper>
                        <FooterLink href="https://returnado.com/" target="_blank">
                            <Icon component={'LogoReturnado'}/>
                        </FooterLink>
                    </FooterLogoWrapper>
            }

        </FooterWrapper>
    )
}
export default Footer
