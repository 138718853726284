const mode = process.env.NODE_ENV !== 'production'
const buildVersion = process.env.REACT_APP_BV || 1
const buildMerchant = process.env.REACT_APP_BM || 'new-prod.returnado.com'//'new-prod.returnado.com'//

// const target = 'https://qa.returnado.com/';
// const target = 'https://admin.vanilla.returnado.com/';
// const target = 'https://dev.returnado.com/';
// const target = 'https://hh-staging.returnado.com/';
// const target = 'https://new-prod.returnado.com/widget-api/v1'
// const target = 'https://musto-staging.returnado.com/widget-api/v1'

const defaultHost = {
	apiUrl: mode
		? 'http://localhost:3000/widget-api/$v'
		: `https://${buildMerchant}/widget-api/v${buildVersion}`,
}
const hosts = {
	localhost: defaultHost,
}

const currentHost = hosts[window.location.hostname]
	? hosts[window.location.hostname]
	: defaultHost

export default currentHost
