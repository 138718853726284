//import React from 'react'

export const fonts = {
	ceraPro: '"Cera Pro", sans-serif',
	openSans: '"Open Sans", sans-serif',
	signika: '"Signika", sans-serif',
	dinPro: '"DINPro", sans-serif',
	optima: '"Optima", sans-serif',
	futura: 'futura-pt, sans-serif',
	WWBeta8Regular: 'WWBeta8Regular, sans-serif',
    IBMPlex: 'IBMPlex, sans-serif',
	PlayfairL: 'PlayfairL, sans-serif',
	AvenirBook: 'AvenirBook',
	Gotham: 'Gotham',
	AmericaExpandedRegular: 'AmericaExpandedRegular',
	usual: 'usual,sans-serif',
	MonumentGrotesk: 'MonumentGrotesk',
	AktivGroteskEx: 'AktivGroteskEx'
}

const size = {
	mobileS: 320,
	mobileM: 375,
	mobileL: 479,
	mobileXL: 551,
	tabletS: 639,
	tablet: 767,
	laptopS: 991,
	laptopM: 1199,
	laptopL: 1439,
	desktopFHD: 1919,
	desktopWQHD: 2559,
	desktopUHD: 3839,
}

export const device = Object.keys(size).reduce((acc, cur) => {
	acc[cur] = `max-width: ${size[cur]}px`
	return acc
}, {})

export const merchantThemes = {
	default: 'default',
	musto: 'musto',
	asket: 'asket',
	royal_design: 'royal_design',
	bjornborg: 'bjornborg',
	hh: 'hh',
	hh_ww: 'hh_ww',
	toteme: 'toteme',
	marysia: 'marysia',
	douchebags: 'douchebags',
	woodwood: 'woodwood',
	aycane: 'aycane',
	bymalina: 'bymalina',
	8848: '8848',
	henrylloyd: 'henrylloyd',
	chimi: 'chimi',
	rum21: 'rum21',
	ogoship: 'ogoship',
	sns: 'sns'
}
