import styled, { css } from 'styled-components'
import { rem } from '../../../utils/common'
import { fonts, merchantThemes, device } from '../../../components/Variable'

export const Btn = styled.button`
	height: ${rem(44)};
	font-family: ${fonts.futura};
	border: 1px solid transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: none;
	padding: ${rem(13, 16)};
	transition: all 0.3s;
	text-decoration: none;
	text-align: center;
	min-width: ${rem(156)};
	font-size: ${rem(16)};
	line-height: ${rem(18)};
	//border-radius: ${rem(10)};
	letter-spacing: ${rem(1.16667)};
	position: relative;
	transform: perspective(1px) translateZ(0);
	
	${(p) => p.theme.name === merchantThemes.marysia && css`
		font-family: ${fonts.futura};
	`}
	${(p) => p.theme.name === merchantThemes.woodwood && css`
		font-family: ${fonts.WWBeta8Regular};
	`}
	${(p) => p.theme.name === merchantThemes.aycane && css`
		font-family: ${fonts.IBMPlex};
	`}
	${(p) => p.theme.name === merchantThemes.bymalina && css`
		font-family: ${fonts.AvenirBook};
	`}
	${(p) => p.theme.name === merchantThemes.royal_design && css`
		font-family: ${fonts.openSans};
	`}
	${(p) => p.theme.name === merchantThemes.henrylloyd && css`
		font-family: ${fonts.Gotham};
	`}
	${(p) => p.theme.name === merchantThemes.chimi && css`
		font-family: ${fonts.AmericaExpandedRegular};
	`}
	${(p) => p.theme.name === merchantThemes.musto && css`
		font-family: ${fonts.openSans};
	`}
	${(p) => p.theme.name === merchantThemes.toteme && css`
		font-family: ${fonts.MonumentGrotesk};
		font-size: ${rem(15)};
		letter-spacing: ${rem(0.06)};
		line-height: ${rem(20)};
	`}
	${(p) => p.theme.name === merchantThemes.hh && css`
		font-family: ${fonts.dinPro};
	`}
	${(p) => p.theme.name === merchantThemes.hh_ww && css`
		font-family: ${fonts.dinPro};
	`}
	${(p) => p.theme.name === merchantThemes.sns && css`
		font-family: ${fonts.AktivGroteskEx};
	`}
	
	@media (${device.tablet}) {
		min-width: ${rem(80)};
		font-size: ${rem(12)};
		height: ${rem(40)};
		padding: ${rem(10)};
		
		${(p) => p.theme.name === merchantThemes.toteme && css`
			font-size: ${rem(15)};
			letter-spacing: ${rem(0.06)};
			line-height: ${rem(20)};
		`}
	}
	&:disabled,
	&[disabled] {
		background-color: ${({ theme }) => theme.color.grayOne}!important;
		border-color: ${({ theme }) => theme.color.grayThirteen}!important;
		color: ${({ theme }) => theme.color.blackOne}!important;
		text-decoration: none !important;
		svg {
			fill: ${({ theme }) => theme.color.blackOne}!important;
		}
	}
	${(props) =>
		props.w100 &&
		css`
			width: 100%;
			flex: 0 0 100%;
			max-width: 100%;
		`}
	${(props) =>
		props.w419 &&
		css`
			width: 100%;
			max-width: ${rem(419)};
			margin: 0 auto;
		`}
	${(props) =>
		props.uppercase &&
		css`
			text-transform: uppercase;
		`}
	${(p) =>
		p.icon &&
		css`
			padding: ${rem(13, 54)};
			border-color: rgba(0, 0, 0, 0.5);
			svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		`}
	${(p) =>
		p.iconPosition === 'left'
			? css`
					svg {
						left: ${rem(15)};
						@media (${device.mobileL}) {
							left: ${rem(10)};
						}
					}
			  `
			: `
		svg{
			right: ${rem(15)};
		}
	`}
`

export const BtnFull = styled(Btn)`
	background-color: ${({ theme }) => theme.color.primary};
	border-color: ${({ theme }) => theme.color.primary};
	color: ${({ theme }) => theme.color.secondary};
	
	${p => p.theme.name === merchantThemes.toteme && css`
		background-color: transparent;
		color: ${({ theme }) => theme.color.primary};
		border-radius: ${rem(32)};
		height: ${rem(33)};
		font-size: ${rem(15)};
		letter-spacing: ${rem(0.06)};
		line-height: ${rem(20)};
		
		
		svg {
			fill: ${({ theme }) => theme.color.primary};
		}
	`}
	
	svg {
		fill: ${({ theme }) => theme.color.secondary};
	}
	
	&:hover {
		background-color: transparent;
		color: ${({ theme }) => theme.color.primary};
		svg {
			fill: ${({ theme }) => theme.color.primary};
		}
	}

	${(props) =>
		props.dark &&
		css`
			background-color: ${({ theme }) => theme.color.blackOne};
			border-color: ${({ theme }) => theme.color.blackOne};
			color: ${({ theme }) => theme.color.secondary};
			${p => p.theme.name === merchantThemes.toteme && css`
				background-color: transparent;
				color: ${({ theme }) => theme.color.primary};
			`}
			&:hover {
				color: ${({ theme }) => theme.color.blackOne};
				svg {
					fill: ${({ theme }) => theme.color.blackOne};
				}
			}
		`};
	${(props) =>
		props.gray &&
		css`
			svg {
				fill: ${({ theme }) => theme.color.blackOne};
			}
			background-color: ${({ theme }) => theme.color.blackOne};
			border-color: ${({ theme }) => theme.color.blackOne};
			color: ${({ theme }) => theme.color.secondary};
			${p => p.theme.name === merchantThemes.toteme && css`
				background-color: transparent;
				color: ${({ theme }) => theme.color.primary};
			`}
			&:hover {
				color: ${({ theme }) => theme.color.blackOne};
			}
		`};
	${(props) =>
		props.selected &&
		css`
			background-color: transparent;
			color: ${({ theme }) => theme.color.primary};
			svg {
				fill: ${({ theme }) => theme.color.primary};
			}
		`};
	${(p) =>
		p.theme.name === merchantThemes.musto &&
		css`
			background-color: ${({ theme }) => theme.color.primary};
			border-color: ${({ theme }) => theme.color.primary};
			color: ${({ theme }) => theme.color.secondary};
			&:hover {
				background-color: transparent;
				color: ${({ theme }) => theme.color.primary};
				svg {
					fill: ${({ theme }) => theme.color.primary};
				}
			}
		`}
`

export const BtnFlow = styled(Btn)`
	height: ${rem(40)};
	letter-spacing: ${rem(0.2)};
	background-color: ${({ theme }) => theme.color.grayOne};
	border-color: ${({ theme }) => theme.color.grayOne};
	color: ${({ theme }) => theme.color.blackOne};
	&:hover {
		background-color: ${({ theme }) => theme.color.secondary};
		border-color: ${({ theme }) => theme.color.primary};
		color: ${({ theme }) => theme.color.primary};
	}

	&:disabled,
	&[disabled] {
		background-color: ${({ theme }) => theme.color.grayOne}!important;
		border-color: ${({ theme }) => theme.color.grayThirteen}!important;
		color: ${({ theme }) => theme.color.blackOne}!important;
		text-decoration: none !important;
	}
`

export const BtnBorder = styled(Btn)`
	background-color: transparent;
	border-color: ${({ theme }) => theme.color.blackOne};
	color: ${({ theme }) => theme.color.blackOne};
	svg {
		fill: ${({ theme }) => theme.color.blackOne};
	}
	&:hover {
		background-color: ${({ theme }) => theme.color.blackOne};
		border-color: ${({ theme }) => theme.color.blackOne};
		color: ${({ theme }) => theme.color.secondary};
		svg {
			fill: ${({ theme }) => theme.color.secondary};
		}
	}

	&:disabled,
	&[disabled] {
		background-color: ${({ theme }) => theme.color.grayOne}!important;
		border-color: ${({ theme }) => theme.color.grayThirteen}!important;
		color: ${({ theme }) => theme.color.blackOne}!important;
		text-decoration: none !important;
		cursor: not-allowed;
	}

	${(p) =>
		p.isOpen &&
		css`
			svg {
				transform: translateY(-50%) rotate(-180deg);
			}
		`}

	@media (${device.mobileM}) {
		margin-bottom: ${rem(10)};
		&.buttonBack {
			margin-bottom: 0;
		}
	}
`
