import { css } from 'styled-components'
import { rem } from '../../../../utils/common'
import {merchantThemes} from "../../../Variable";

export const CommonStyles = css`
	position: relative;
	display: inline-block;
	//padding-left: ${rem(32)};
	line-height: ${rem(20)};
	margin: 0;
	input {
		position: absolute;
		left: 0;
		bottom: 0;
		opacity: 0;
	}
	 > span {
		display: inline-block;
		cursor: pointer;
		font-size: ${rem(14)};
		line-height: ${rem(20)};
		
		${(p) => p.theme.name === merchantThemes.toteme && css`
		    font-size: ${rem(15)};
		    letter-spacing: ${rem(0.06)};
		    line-height: ${rem(20)};
		`}
		
		.checkbox-wrapper,
		.checkbox-check {
			content: '';
			position: absolute;
			transition: all 0.3s;
			cursor: pointer;
		}
		.checkbox-wrapper {
			top: ${rem(5)};
			border: 1px solid ${({ theme }) => theme.color.primary};
			width: ${rem(20)};
			height: ${rem(20)};
			display: inline-block;
			position: relative;
			margin-right: ${rem(5)};
			
			${p => p.theme.name !== merchantThemes.toteme && css`
              border-radius: ${rem(4)};
            `}
		}
		.checkbox-check {
			width: ${rem(12)};
			height: ${rem(8)};
			border-left: 2px solid transparent;
			border-bottom: 2px solid transparent;
			transform: rotate(-50deg);
			left: ${rem(3)};
			top: ${rem(3)};
			width: ${rem(13)};
			height: ${rem(7)};
		}
	}

	a {
		margin-bottom: 0;
	}

	span {
		.checkbox-wrapper {
			border-color: ${({ theme }) => theme.color.blackOne};
		}
	}

	input:checked + span {
		.checkbox-wrapper {
			background-color: ${({ theme }) => theme.color.blackOne};
		}
		.checkbox-check {
			border-color: ${({ theme }) => theme.color.secondary};
		}
	}

	input:disabled + span {
		&.checkbox-wrapper {
		}
		&.checkbox-check {
		}
	}
`
